import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import PatientUsersProxy from 'api/patientUsers/patientUsersProxy';

import PageLayout from 'global_elements/Layouts/PageLayout';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import CollapsibleSection from 'global_elements/Layouts/CollapsibleSection';
import SecondaryHeader from 'global_elements/Text/SecondaryHeader';
import TertiaryHeader from 'global_elements/Text/TertiaryHeader';
import InlineText from 'global_elements/Text/InlineText';
import ProviderAssessmentCard from 'global_elements/Layouts/Cards/ProviderAssessmentCard';
import PatientInfoCard from 'global_elements/Layouts/Cards/PatientInfoCard';

import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { PatientData, getNewPatientData } from 'interfaces/patients/patientData';
import { AssessmentCardSectionContainer, ProviderAssessmentCardProps } from 'types/cardProps';
import FacilityUsersProxy from 'api/facilityUsers/facilityUsersProxy';
import { AssessmentLocationState } from 'types/CoreMeasurePatient';
import { AssessmentData } from 'interfaces/assessments/assessmentData';
import { ProviderAssessmentTypes } from 'constants/assessment_types';
import MHODateTime from 'domain/dateTime/MHODateTime';
import { UserContext } from 'context/user';
import { AccessType } from 'types/facilityUserAccount';

import FeatureFlagManager from 'config/featureFlags/FeatureFlagManager';
import { FeatureFlagName } from 'config/featureFlags';
import ApiDataUiConverter from '../assessments/ApiDataUiConvert';

import './styles.scss';

type uriParams = {
  patient: string;
};

/**
 * Checks and gets the list of assessment card props from the API assessment list.
 *
 * @param assessmentList list of API assessment list. Could be null.
 * @param assessmentTypeFilter optional filter to narrow the type 'ToDo', 'SelfCheck' and 'History'.
 * @returns a converted API assessment list to Card Prop.
 */
function getAssessmentCardProps(assessmentList: AssessmentData[] | null, assessmentTypeFilter?: ProviderAssessmentTypes, isCoreMeasuresMode?: boolean): ProviderAssessmentCardProps[] {
  let cardProps: ProviderAssessmentCardProps[] = [];
  if (assessmentList) {
    cardProps = ApiDataUiConverter.convertProviderAssessmentsToCardProps(assessmentList, assessmentTypeFilter, isCoreMeasuresMode);
  }
  return cardProps;
}

const PatientDetails = (): JSX.Element => {
  const { patient } = useParams<uriParams>();
  const { user } = React.useContext(UserContext);
  const [currentCards, setCurrentCards] = React.useState<ProviderAssessmentCardProps[]>([]);
  const [submittedCards, setSubmittedCards] = React.useState<ProviderAssessmentCardProps[]>([]);
  const [historyCards, setHistoryCards] = React.useState<ProviderAssessmentCardProps[]>([]);
  const [coreMeasuresCards, setCoreMeasuresCards] = React.useState<ProviderAssessmentCardProps[]>([]);
  const [submittedCoreMeasuresCards, setSubmittedCoreMeasuresCards] = React.useState<ProviderAssessmentCardProps[]>([]);
  const [patientInfo, setPatientInfo] = React.useState<PatientData>(getNewPatientData());
  const location = useLocation<AssessmentLocationState>();
  const [coreMeasuresRedirect] = React.useState(location.state?.coreMeasuresRedirect ? location.state.coreMeasuresRedirect : 0);
  const [loadingCurrent, setLoadingCurrent] = React.useState<boolean>(true);
  const [loadingCoreMeasures, setLoadingCoreMeasures] = React.useState<boolean>(true);
  const [loadingHistory, setLoadingHistory] = React.useState<boolean>(true);

  const hasCoreMeasuresAccess = (): boolean => {
    if (user && FeatureFlagManager.get<boolean>(FeatureFlagName.isCoreMeasuresEnabled) && (user.accessType === AccessType.ALL_ACCESS || user.accessType === AccessType.CORE_MEASURES)) {
      return true;
    }
    return false;
  };
  const hasOutcomesAccess = (): boolean => {
    // If core measures is not enabled, just return true.
    if (!FeatureFlagManager.get<boolean>(FeatureFlagName.isCoreMeasuresEnabled)) {
      return true;
    }
    // Else, need to check access type
    return Boolean(user && (user.accessType === AccessType.ALL_ACCESS || user.accessType === AccessType.OUTCOMES));
  };

  React.useEffect(() => {
    const patientId = parseInt(patient, 10);
    PatientUsersProxy.getPatient(
      patientId,
      (response) => {
        if (response.data) {
          const loadedPatientData: PatientData = response.data[0];
          setPatientInfo(loadedPatientData);
        }
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );

    if (hasOutcomesAccess()) {
      FacilityUsersProxy.getPatientAssessments(patientId)
        .then((assessmentList) => {
          if (assessmentList && assessmentList.length) {
            const currentCardProps: ProviderAssessmentCardProps[] = getAssessmentCardProps(assessmentList, ProviderAssessmentTypes.TODO);
            const submittedCardProps: ProviderAssessmentCardProps[] = getAssessmentCardProps(assessmentList, ProviderAssessmentTypes.SUBMITTED);
            setCurrentCards(currentCardProps);
            setSubmittedCards(submittedCardProps);
          }
        })
        .catch((errorResponse) => {
          console.log(errorResponse);
        })
        .finally(() => setLoadingCurrent(false));
    } else {
      setLoadingCurrent(false);
    }

    if (hasCoreMeasuresAccess()) {
      FacilityUsersProxy.getPatientCoreMeasuresAssessments(patientId)
        .then((assessmentList) => {
          if (assessmentList && assessmentList.length) {
            const coreMeasuresCardProps: ProviderAssessmentCardProps[] = getAssessmentCardProps(assessmentList, ProviderAssessmentTypes.TODO, true);
            const submittedCoreMeasuresCardProps: ProviderAssessmentCardProps[] = getAssessmentCardProps(assessmentList, ProviderAssessmentTypes.SUBMITTED, true);
            setCoreMeasuresCards(coreMeasuresCardProps);
            setSubmittedCoreMeasuresCards(submittedCoreMeasuresCardProps);
          }
        })
        .catch((errorResponse) => {
          console.log(errorResponse);
        })
        .finally(() => setLoadingCoreMeasures(false));
    } else {
      setLoadingCoreMeasures(false);
    }

    FacilityUsersProxy.getPatientHistoryAssessments(patientId)
      .then((assessmentList) => {
        if (assessmentList && assessmentList.length) {
          const historyCardProps = getAssessmentCardProps(assessmentList, ProviderAssessmentTypes.HISTORY);
          setHistoryCards(historyCardProps);
        }
      })
      .catch((errorResponse) => {
        console.log(errorResponse);
      })
      .finally(() => setLoadingHistory(false));
  }, [patient]);

  const onTabSelect = (index: number, lastIndex: number): boolean => {
    if (index === lastIndex) {
      return false;
    }

    switch (index) {
      case 0: // Current Assessments
        break;
      case 1: // History Assessments
        break;
      case 2: // Core Measures Assessments
        break;
      default:
        break;
    }

    return true;
  };

  const hasCurrentAssessments = (): boolean => currentCards.length > 0 || submittedCards.length > 0;
  const hasCoreMeasuresAssessments = (): boolean => coreMeasuresCards.length > 0 || submittedCoreMeasuresCards.length > 0;

  const getCoreMeasuresTabIndex = (): number => {
    if (hasCurrentAssessments() && hasOutcomesAccess()) {
      return 1;
    }
    return 0;
  };

  const assessmentCard = (content: ProviderAssessmentCardProps): JSX.Element => (
    <ProviderAssessmentCard
      id={content.id}
      title={content.title}
      appliedWhen={content.appliedWhen}
      appliedWhenID={content.appliedWhenID}
      instrumentTypeID={content.instrumentTypeID}
      instrumentType={content.instrumentType}
      careID={content.careID}
      patientID={content.patientID}
      assessmentNumber={content.assessmentNumber}
      completed={content.completed}
      ctas={content.ctas}
      assessmentLinkEmail={content.assessmentLinkEmail} // Last email this was sent to (null if never sent before)
      assessmentLinkSentDate={content.assessmentLinkSentDate} // Last date an email was sent (null if never sent before)
      availableForPatient={content.availableForPatient} // Whether an email can be sent to the patient
      isCoreMeasures={content.isCoreMeasures}
      sequenceNumber={content.sequenceNumber}
      loadAnswersFlag={content.isCoreMeasures && (content.assessmentStatus === 'Incomplete' || content.assessmentStatus === 'Completed')}
      dischargeDisp={content.dischargeDisp}
      submissionFrozen={content.submissionFrozen}
    />
  );

  const currentAssessments = (): JSX.Element => {
    const hasCurrentCards = currentCards && currentCards.length > 0;
    const hasSubmittedCards = submittedCards && submittedCards.length > 0;
    return (
      <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="current-assessments-panel">
        {hasCurrentCards && (
          <>
            <TertiaryHeader text="To-Do" fontColor={FontColors.DARK} marginBottomPx={16} />
            <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} wrap extraClasses="assessment-card-container">
              {currentCards.map((card) => assessmentCard(card))}
            </FlexContainer>
          </>
        )}
        {hasSubmittedCards && (
          <>
            <hr />
            <TertiaryHeader text="Submitted" fontColor={FontColors.DARK} marginBottomPx={16} />
            <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} wrap extraClasses="assessment-card-container">
              {submittedCards.map((card) => assessmentCard(card))}
            </FlexContainer>
          </>
        )}
      </FlexContainer>
    );
  };

  const coreMeasuresAssessments = (): JSX.Element => {
    const hasCurrCoreMeasuresCards = coreMeasuresCards && coreMeasuresCards.length > 0;
    const hasSubmittedCoreMeasuresCards = submittedCoreMeasuresCards && submittedCoreMeasuresCards.length > 0;
    return (
      <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="current-assessments-panel">
        {hasCurrCoreMeasuresCards && (
          <>
            <TertiaryHeader text="To-Do" fontColor={FontColors.DARK} marginBottomPx={16} />
            <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} wrap extraClasses="assessment-card-container">
              {coreMeasuresCards.map((card) => assessmentCard(card))}
            </FlexContainer>
          </>
        )}
        {hasSubmittedCoreMeasuresCards && (
          <>
            <hr />
            <TertiaryHeader text="Submitted" fontColor={FontColors.DARK} marginBottomPx={16} />
            <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.SPACE_BETWEEN} wrap extraClasses="assessment-card-container">
              {submittedCoreMeasuresCards.map((card) => assessmentCard(card))}
            </FlexContainer>
          </>
        )}
      </FlexContainer>
    );
  };

  const getHistorySections = (): any => {
    // Seperate by each stay (dateAdmitted - dateDischarged)
    const sectionContainers: AssessmentCardSectionContainer[] = [];
    let stays: string[] = [];

    // Sort by descending completed date
    const sortedCards = historyCards.sort((a, b) => {
      const firstDate = new Date(b.completed ? b.completed : '');
      const lastDate = new Date(a.completed ? a.completed : '');
      if (firstDate < lastDate) return -1;
      if (firstDate > lastDate) return 1;
      return 0;
    });

    // Get dates for sections (dateAdmintted - dateDischarged)
    sortedCards.forEach((val) => {
      const admitted = val.dateAdmitted ? val.dateAdmitted : '';
      if (admitted && stays.indexOf(admitted) < 0) {
        stays.push(admitted);
        sectionContainers.push({ title: '', entries: [] });
      }
    });
    stays = stays.sort();

    const getSectionTitle = (section: ProviderAssessmentCardProps): string => {
      const admitted = new MHODateTime(section.dateAdmitted ? section.dateAdmitted : '');
      const discharged = new MHODateTime(section.dateDischarged ? section.dateDischarged : '');
      return `${admitted.getFormattedCalendarDate()} - ${discharged.getFormattedCalendarDate()}`;
    };

    // Initialize card properties
    sortedCards.forEach((card) => {
      if (card.dateAdmitted) {
        const yearIndex = stays.indexOf(card.dateAdmitted);
        const section = sectionContainers[yearIndex];
        section.title = getSectionTitle(card);
        section.entries.push(card as ProviderAssessmentCardProps);
        section.entries = section.entries.sort((a: any, b: any) => (a.completed > b.completed ? 1 : -1));
      }
    });

    // const sections = [].concat([], ...sectionContainers);
    const sections: AssessmentCardSectionContainer[] = Array.from(sectionContainers);
    const filteredSections = sections.reverse().filter((section: AssessmentCardSectionContainer) => section.entries.length);

    return filteredSections.map((section: AssessmentCardSectionContainer) => (
      <CollapsibleSection bottomDivider banner={<TertiaryHeader text={section.title} fontColor={FontColors.PRIMARY} />}>
        {section.entries.filter((card: ProviderAssessmentCardProps) => !card.isCoreMeasures).length > 0 && (
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} wrap extraClasses="assessment-card-container history-cards">
            <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} wrap extraClasses="assessment-card-container history-cards">
              <TertiaryHeader text="Assessments" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
              <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} wrap extraClasses="assessment-card-container history-cards">
                {section.entries.filter((card: ProviderAssessmentCardProps) => !card.isCoreMeasures).map((card: ProviderAssessmentCardProps) => assessmentCard(card))}
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        )}

        {section.entries.filter((card: ProviderAssessmentCardProps) => card.isCoreMeasures).length > 0 && hasCoreMeasuresAccess() && (
          <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} wrap extraClasses="assessment-card-container history-cards">
            <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} wrap extraClasses="assessment-card-container history-cards">
              <TertiaryHeader text="Core Measures" fontColor={FontColors.PRIMARY} marginBottomPx={16} />
              <FlexContainer display={DisplayVariant.FLEX_ROW} align={AlignVariant.START} justify={JustifyVariant.START} wrap extraClasses="assessment-card-container history-cards">
                {section.entries.filter((card: ProviderAssessmentCardProps) => card.isCoreMeasures).map((card: ProviderAssessmentCardProps) => assessmentCard(card))}
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        )}
      </CollapsibleSection>
    ));
  };

  const historyAssessments = (): JSX.Element => (
    <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="history-assessments-panel">
      {getHistorySections()}
    </FlexContainer>
  );

  return (
    <PageLayout
      layout={PageLayoutVariant.PADDED}
      testText="Patient Details Page"
      bannerCard={(
        <PatientInfoCard
          patientID={patient}
          patientCareID={patientInfo.careID}
          patientFirstName={patientInfo.patientFirstName}
          patientLastName={patientInfo.patientLastName}
          patientDOB={patientInfo.dateOfBirth}
          patientAccountNumber={patientInfo.patientNumber}
          patientMedicalRecordNumber={patientInfo.medicalRecordNumber}
          patientProgram={patientInfo.programName}
          patientAdmissionDate={patientInfo.dateAdmitted}
          patientDischargeDate={patientInfo.dateDischarged}
          patientRegistrationPIN={patientInfo.registrationPIN?.toString() ?? ''}
          showRegisterButton
          disableRegisterButton={FeatureFlagManager.get<boolean>(FeatureFlagName.isCoreMeasuresEnabled) && user?.accessType === AccessType.CORE_MEASURES}
        />
      )}
    >
      <FlexContainer display={DisplayVariant.FLEX_COL} align={AlignVariant.START} justify={JustifyVariant.START} extraClasses="patient-details-page-content">
        <SecondaryHeader text="Assessments" fontColor={FontColors.PRIMARY} />
        {loadingCurrent || loadingCoreMeasures || loadingHistory ? (
          <TertiaryHeader text="Loading..." fontColor={FontColors.PRIMARY} />
        ) : (
          <Tabs onSelect={onTabSelect} defaultIndex={coreMeasuresRedirect ? getCoreMeasuresTabIndex() : 0}>
            <TabList>
              {hasCurrentAssessments() && hasOutcomesAccess() && (
                <Tab>
                  <InlineText text="Current" fontColor={FontColors.DARK} fontSize={FontSizes.LARGE} />
                </Tab>
              )}
              {hasCoreMeasuresAssessments() && hasCoreMeasuresAccess() && (
                <Tab>
                  <InlineText text="Core Measures" fontColor={FontColors.DARK} fontSize={FontSizes.LARGE} />
                </Tab>
              )}
              <Tab>
                <InlineText text="History" fontColor={FontColors.DARK} fontSize={FontSizes.LARGE} />
              </Tab>
            </TabList>
            {hasCurrentAssessments() && hasOutcomesAccess() && <TabPanel>{currentAssessments()}</TabPanel>}
            {hasCoreMeasuresAssessments() && hasCoreMeasuresAccess() && <TabPanel>{coreMeasuresAssessments()}</TabPanel>}
            <TabPanel>{historyAssessments()}</TabPanel>
          </Tabs>
        )}
      </FlexContainer>
    </PageLayout>
  );
};

export default PatientDetails;
