import React, { useContext, useEffect, useState } from 'react';
import * as Survey from 'survey-react';
import { useParams, useHistory, Prompt, useLocation } from 'react-router-dom';
import ApiResponse from 'api/lib/models/ApiResponse';
import UserProxy from 'api/user/userProxy';
import PatientUsersProxy from 'api/patientUsers/patientUsersProxy';
import { UserContext } from 'context/user';
import { Roles } from 'constants/roles';
import { PatientData } from 'interfaces/patients/patientData';
import PageLayout from 'global_elements/Layouts/PageLayout';
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import InlineText from 'global_elements/Text/InlineText';
import { FontColors, FontSizes } from 'global_elements/Text/variants';

import { NETWORK_ERROR_MESSAGE } from 'constants/errorMessages';
import AssessmentProxy from 'api/assessments/assessmentProxy';
import { AssessmentResult, EventAssessmentResult } from 'api/assessments/types';
import { AllRoutes } from 'constants/routes';
import { AssessmentLocationState } from 'types/CoreMeasurePatient';
import { AssessmentSurvey } from '../assessments/assessment';
import { getAssessmentResultsDescriptor } from '../assessments/shared';

/**
 * Patient's assessment page.
 */
type uriParams = {
  assessmentNumber: string;
  instrumentTypeID: string;
  appliedWhenID: string;
  patientID: string;
};

/**
 * A HTML page wrapper to create and retrieve html with the Assessment (SurveyJS), with the optional
 * pre-populated data if it exists.
 *
 * @returns assessment html.
 */
function AssessmentPage(): JSX.Element {
  const history = useHistory();
  const location = useLocation<AssessmentLocationState>();
  const { user } = useContext(UserContext);
  const { assessmentNumber, instrumentTypeID, appliedWhenID, patientID } = useParams<uriParams>();
  const [assessmentPatientID, setPatientID] = useState<number>(0);
  const [patient, setPatient] = useState<PatientData | null>(null);
  const [completedSurvey, setCompletedSurvey] = React.useState<Survey.Model | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isCoreMeasures, setIsCoreMeasures] = useState(0); // Set by child survey component
  const [sequenceNumber] = useState(location.state?.sequenceNumber ? location.state.sequenceNumber : 1);
  const [loadAnswersFlag] = useState<boolean>(location.state?.loadAnswersFlag ? location.state.loadAnswersFlag : false);
  const [dischargeDisp] = useState<number | null>(location.state?.dischargeDisp ? location.state.dischargeDisp : null);

  const patientIDInt = patientID ? parseInt(patientID, 10) : 0;
  const instrumentTypeIDInt = parseInt(instrumentTypeID, 10);
  const assessmentAppliedWhenID = parseInt(appliedWhenID, 10);
  const assessmentNumberInt = parseInt(assessmentNumber, 10);

  /**
   * Override the generic SurveyJS error message with a custom error message.
   *
   * @param message The message to replace the default error message with
   */
  const overrideErrorMessage = (message: string): void => {
    const error = document.querySelector('.sv_save_msg__error');
    if (error) {
      const errorSpan = error.firstChild;
      if (errorSpan) {
        errorSpan.textContent = message;
      }
    }
  };

  /**
   * Formats the results of the completed survey to send to the Database via API.
   *
   * @param results An object containing the form answers.
   */
  const convertAnswersForAPI = (results: [string, unknown][]): AssessmentResult[] => {
    // Construct the data format for the API.
    const resultsList: AssessmentResult[] = [];
    for (let i = 0; i < results.length; i += 1) {
      const resultRow = {
        patientID: assessmentPatientID,
        assessmentNumber: assessmentNumberInt,
        variableName: results[i][0],
        dataValue: String(results[i][1]),
      };
      resultsList.push(resultRow);
    }

    return resultsList;
  };

  /**
   * Formats the results of the completed survey to send to the Database via API.
   * This method is for the core measures seclusion / restraint form. It has a special dynamic panel
   * question, which is in an array format. The nested for loops are used to read each individual answer
   * from the array of objects.
   * Additionally, each form in the database can only contain 12 events. The form in the UI allows for unlimited responses, but
   * the answers must be split up into sets of 12 when submitting.
   * @param results An object containing the form answers.
   */
  const convertSecResAnswersForAPI = (results: [string, unknown][]): EventAssessmentResult[] => {
    // Construct the data format for the API.
    const resultsList: EventAssessmentResult[] = [];
    const maxEventCount = 12; // The maximum # of events for a single form is 12

    // Put events into a separate array
    const foundEvents = results.find((x) => x[0] === 'secRes');
    const eventsList = foundEvents && foundEvents.length > 0 ? (foundEvents[1] as any) : [];

    // On the first pass through the answers, look at all questions except for the dynamic panel.
    for (let i = 0; i < results.length; i += 1) {
      if (!Array.isArray(results[i][1])) {
        // Each answer needs to be added once per form (per 12 events)
        for (let j = 0; j < eventsList.length / 86 + 1; j += 1) {
          const resultRow = {
            patientID: assessmentPatientID,
            assessmentNumber: assessmentNumberInt,
            eventSetSequenceNumber: j + 1,
            variableName: results[i][0],
            dataValue: String(results[i][1]),
          };
          resultsList.push(resultRow);
        }
      }
    }

    // Iterate through events list
    for (let i = 0; i < eventsList.length; i += 1) {
      const innerObject = (eventsList as Array<any>)[i];
      const innerResults = Object.entries(innerObject);
      // Iterate through fields
      for (let j = 0; j < innerResults.length; j += 1) {
        // Need to make 1-indexed instead of 0-indexed
        // Also need event 12's index to be "12" and not "0"
        const eventIndex = (i + 1) % maxEventCount === 0 ? maxEventCount : (i + 1) % maxEventCount;
        const resultRow = {
          patientID: assessmentPatientID,
          assessmentNumber: assessmentNumberInt,
          eventSetSequenceNumber: Math.floor(i / maxEventCount) + 1,
          variableName: innerResults[j][0] + eventIndex,
          dataValue: String(innerResults[j][1]),
        };

        resultsList.push(resultRow);
      }
    }

    return resultsList;
  };

  /**
   * Callback function to handle Survey (Assessment) completion event.
   *
   * @param survey Completed Survey (Assessment) from the SurveyJS.
   */
  const onAssessmentCompleteCallback = async (survey: Survey.Model, options: any): Promise<void> => {
    options.showDataSaving();
    setIsDirty(false);

    try {
      const results = Object.entries(survey.data);

      const promises: Promise<void>[] = [];

      // Reflections test form and Seclusion / Restraint form have special conversion and submission methods
      if (assessmentNumberInt === 100014 || assessmentNumberInt === 100031) {
        const eventSubmissionData = convertSecResAnswersForAPI(results);
        promises.push(AssessmentProxy.saveEventAssessmentForm(eventSubmissionData));
      } else {
        const submissionData = convertAnswersForAPI(results);
        promises.push(AssessmentProxy.saveAssessmentForm(submissionData));
      }

      // Resolve promises
      Promise.all(promises)
        .then(() => {
          options.showDataSavingSuccess();
          setCompletedSurvey(survey);
        })
        .catch((error: any) => {
          // Slight delay to show loading message briefly
          setTimeout(() => {
            options.showDataSavingError();
            if (error.message && error.message === 'Network Error') {
              overrideErrorMessage(NETWORK_ERROR_MESSAGE);
            }
          }, 500);

          throw error;
        });
    } catch (error: any) {
      // Slight delay to show loading message briefly
      setTimeout(() => {
        options.showDataSavingError();
        if (error.message && error.message === 'Network Error') {
          overrideErrorMessage(NETWORK_ERROR_MESSAGE);
        }
      }, 500);

      throw error;
    }
  };

  /**
   * Retrieve a flag on whether an assessment's been modified.
   *
   * @returns flag true if the assessment's been modified.
   */
  const isDirtyAssessment = (): boolean => !completedSurvey && isDirty;

  /**
   * Override the beforeunload event to ensure the <Prompt> for 'save before close' dialog is triggered on browser back/refresh.
   *
   * @param event beforeunload event
   */
  const handlePageClosePrompt = (event: any): any => {
    event.preventDefault();
    event.returnValue = ''; // eslint-disable-line no-param-reassign
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handlePageClosePrompt);
    return () => {
      setIsDirty(false);
      window.removeEventListener('beforeunload', handlePageClosePrompt);
    };
  }, []);

  // When the survey is complete, redirect to the results page.
  useEffect(() => {
    if (!patient) {
      return;
    }

    if (completedSurvey) {
      // When the survey is complete, redirect to the results page.
      const assessmentResultsDescriptor = getAssessmentResultsDescriptor(completedSurvey, {
        assessmentNumber: assessmentNumber.toString(),
        assessmentPatientId: patientIDInt,
        careId: patient.careID,
        instrumentTypeId: instrumentTypeIDInt,
      });

      // If core measure assessment, redirect to patient dashboard instead of results page.
      if (isCoreMeasures) {
        // Add delay to allow the DB procedures to complete and avoid showing no results
        setTimeout(() => {
          history.push(`${AllRoutes.PATIENT_ACCOUNTS}/${patientID}`, {
            coreMeasuresRedirect: isCoreMeasures,
          });
        }, 2000);
      } else if (assessmentResultsDescriptor) {
        setTimeout(() => {
          history.push(assessmentResultsDescriptor);
        }, 5000);
      }
    }
  }, [completedSurvey]);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.role === Roles.PATIENT) {
      const userAccountIdStr = user ? user.accountId : '-1';
      const userAccountId = parseInt(userAccountIdStr, 10);

      UserProxy.getUserAccountInfo(
        userAccountId,
        (accountResponse: ApiResponse<any[]>) => {
          console.assert(accountResponse.data?.length === 1, 'More than one user found.');
          if (accountResponse.data?.length !== 1) {
            return;
          }

          const userData = accountResponse.data[0];
          setPatientID(userData.patientID);
        },
        (errorResponse) => {
          console.log(errorResponse);
        },
      );
    } else {
      setPatientID(patientIDInt);
    }
  }, [user]);

  useEffect(() => {
    if (assessmentPatientID > 0) {
      PatientUsersProxy.getPatient(
        assessmentPatientID,
        (response: any) => {
          if (response.data) {
            const patientData: PatientData = response.data[0];
            setPatient(patientData);
          }
        },
        (errorResponse: any) => {
          console.log(errorResponse);
        },
      );
    }
  }, [assessmentPatientID]);

  if (!patient) {
    return (
      <PageLayout layout={PageLayoutVariant.PADDED} testText="Assessments Page" loadingText="Loading...">
        <InlineText text="Loading..." fontColor={FontColors.PRIMARY} fontSize={FontSizes.SUPER_EXTRA_LARGE} />
      </PageLayout>
    );
  }

  return (
    <>
      <AssessmentSurvey
        assessmentNumber={assessmentNumberInt}
        appliedWhenID={assessmentAppliedWhenID}
        patientAssessmentData={{
          accountID: patient.accountID!,
          patientID: patient.patientID!,
          programCode: patient.programCode,
          patientNumber: patient.patientNumber,
          careID: patient.careID,
          treatmentTypeID: patient.treatmentTypeID,
          corporationID: patient.corporationID,
          populationTypeID: patient.populationTypeID,
        }}
        patientBannerData={{
          patientID: patient.patientID,
          patientFirstName: patient.patientFirstName,
          patientLastName: patient.patientLastName,
          dateOfBirth: patient.dateOfBirth,
          patientNumber: patient.patientNumber,
          medicalRecordNumber: patient.medicalRecordNumber,
          programName: patient.programName,
          dateAdmitted: patient.dateAdmitted,
          registrationPIN: patient.registrationPIN,
        }}
        onCompleteCallback={onAssessmentCompleteCallback}
        setIsDirtyCallback={setIsDirty}
        setIsCoreMeasures={setIsCoreMeasures}
        sequenceNumber={sequenceNumber}
        loadAnswersFlag={loadAnswersFlag}
        dischargeDisp={dischargeDisp}
      />
      <Prompt when={isDirtyAssessment()} message="Are you sure you want to close the form? Incomplete assessments will be lost." />
    </>
  );
}

export default AssessmentPage;
