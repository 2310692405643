import React from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { AssessmentResultsWithExtremeDefinition } from 'domain/Forms/MHO/AssessmentResultsDefinition';
import { PatientAssessmentDefinition } from 'domain/Forms/MHO/PatientAssessmentDefinition';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import InlineText from 'global_elements/Text/InlineText';
import { AlternatingBasicTableStyle } from 'global_elements/Layouts/DataTables/styles';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { AssessmentResultRow } from 'types/tableProps';
import MHODateTime from 'domain/dateTime/MHODateTime';
import { AssessmentSetupMode, getAssessmentSetupMode } from 'constants/assessment_types';

import Utilities from 'api/lib/Utilities';
import AssessmentProxy from 'api/assessments/assessmentProxy';

import ApiDataUiConverter from '../assessments/ApiDataUiConvert';
import { AssessmentCallbackData } from './assessmentCallbackData';
import './styles.scss';

const NoDataComponent: JSX.Element = <InlineText text="No assessment result found." fontColor={FontColors.PRIMARY} fontSize={FontSizes.LARGE} />;

const LoadingComponent: JSX.Element = (
  <div className="table-loading-spinner">
    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
    <span>Loading...</span>
  </div>
);

const selectedColumnClass = 'selected-column-name';
const comparisonColumnClass = 'comparison-column-name';

const selectedTitleHeader: JSX.Element = <div className={selectedColumnClass}>Select Assessment...</div>;

const comparisonTitleHeader = <div className={comparisonColumnClass}>Compare</div>;

type tableProps = {
  isPatientView: boolean;
  suppressComparisonColumn: boolean;
  isLoading: boolean;
  assessmentTableData: AssessmentResultRow[];
  isCoreMeasures: boolean;
};

const AssessmentColumnTable = (props: tableProps): JSX.Element => {
  /**
   * Checks if the extreme response message exists for the cell. If it exists, return a
   * class type to denote a color change.ß
   *
   * @param extremeResponseMessage user facing extreme message.
   * @returns an extra color class if the value has the extreme response message.
   */
  const getExtremeResponseCellStyle = (extremeResponseMessage: string): string => {
    if (extremeResponseMessage) {
      return 'extreme-response-cell';
    }
    return '';
  };

  const columns = React.useMemo(
    (): TableColumn<AssessmentResultRow>[] => [
      {
        name: props.isCoreMeasures ? 'Data Element' : 'Questions',
        selector: (row) => row.question,
        sortable: false,
        grow: 1,
        wrap: true,
      },
      {
        name: props.isCoreMeasures ? 'Response' : selectedTitleHeader,
        selector: (row) => row.selectedAnswer,
        sortable: false,
        grow: 0,
        minWidth: '25%',
        maxWidth: '25%',
        wrap: true,
        cell: (row) => (
          <>
            {(props.isPatientView || !props.suppressComparisonColumn) && (
              row.selectedAnswer
            )}
            {(!props.isPatientView && props.suppressComparisonColumn) && (
              <div className={getExtremeResponseCellStyle(row.latestExtremeMessage)}>{row.selectedAnswer}</div>
            )}
          </>
        ),
      },
      {
        name: comparisonTitleHeader,
        selector: (row) => row.latestAnswer,
        sortable: false,
        grow: 0,
        minWidth: '25%',
        maxWidth: '25%',
        wrap: true,
        cell: (row) => <div className={getExtremeResponseCellStyle(row.latestExtremeMessage)}>{row.latestAnswer}</div>,
        omit: props.isPatientView || props.suppressComparisonColumn, // Hide this column for patient view.
      },
      {
        name: '',
        sortable: false,
        grow: 0,
        minWidth: '2rem',
        maxWidth: '2rem',
        cell: (row) => !props.isPatientView && row.latestExtremeMessage && <FontAwesomeIcon icon={faWarning} className="icon-control warning-icon" />,
        style: {
          padding: '0 5px',
          fontSize: '1.25rem',
        },
      },
    ],
    [],
  );

  return (
    <DataTable
      dense
      highlightOnHover
      persistTableHead
      progressPending={props.isLoading}
      progressComponent={LoadingComponent}
      customStyles={AlternatingBasicTableStyle}
      columns={columns}
      data={props.assessmentTableData}
      noDataComponent={NoDataComponent}
    />
  );
};

type TableComponentProps = {
  // latestResponse: undefined = uninitialized. null = assessment not found after checking.
  latestResponse: PatientAssessmentDefinition | undefined | null;
  selectedAssessment: AssessmentCallbackData | null;
  isPatientView?: boolean;
  suppressComparisonColumn?: boolean;
  setupMode?: string;
  subscaleSequence?: number;
  onLoaded?: () => void;
  assessmentJsonQuestions?: any;
};

type TableComponentState = {
  tableIsLoading: boolean;
  assessmentTableData: AssessmentResultRow[];
  foundExtremeResponse: boolean;
  extremeResponseGeneralMessage: string;
};

/**
 * Manage the SurveyJS table for the Assessment responses. Displays the questions and responses,
 * where the responses are selectable and dynamically populated based on the external selections.
 */
export class AssessmentResultsTableComponent extends React.Component<TableComponentProps, TableComponentState> {
  prevSelectedAssessment: AssessmentCallbackData | null = null;

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    isPatientView: true,
    suppressComparisonColumn: false,
    setupMode: AssessmentSetupMode.OVERALL,
    subscaleSequence: 0,
    onLoaded: () => ({}),
    assessmentJsonQuestions: [],
  };

  constructor(props: TableComponentProps) {
    super(props);

    this.state = {
      tableIsLoading: true,
      assessmentTableData: [],
      foundExtremeResponse: false,
      extremeResponseGeneralMessage: '',
    };
  }

  componentDidMount(): void {
    if (this.props.latestResponse && Utilities.isEmpty(this.state.assessmentTableData)) {
      this.getLatestAssessmentResponse(this.props.latestResponse);
    } else if (this.props.latestResponse === null) {
      // responses doesn't exist.
      this.setState({ tableIsLoading: false });
      if (this.props.onLoaded) this.props.onLoaded();
    }
  }

  componentDidUpdate(): void {
    this.selectAssessment();
  }

  /**
   * Retrieves the sequence number if set, or 0 if it's undefined.
   *
   * @returns the sequence number. 0, if undefined.
   */
  private getSequenceNumber(): number {
    return this.props.subscaleSequence || 0;
  }

  /**
   * This updates the table results with question text from the FormJson, so the latest updated
   * question copy is available in the tables (for some reason, this is not being updated in the
   * MHO database).
   * @param results
   * @returns
   */
  private getResultsWithUpdatedQuestionText(results: any[]): AssessmentResultsWithExtremeDefinition[] {
    const assessmentResults: AssessmentResultsWithExtremeDefinition[] = results;
    const updatedResults = assessmentResults.map((r: any) => {
      const result = r;
      const foundResult = this.props.assessmentJsonQuestions.find((q: any) => q.name === result.variableName);
      if (foundResult) {
        result.questionText = this.stripHtml(foundResult.title);
      }
      return result;
    });
    return updatedResults;
  }

  /**
   * Retrieve the latest assessment response given the identifiers.
   *
   * @param latestAssessment identifiers for the latest assessments.
   */
  getLatestAssessmentResponse(latestAssessment: PatientAssessmentDefinition): void {
    const setupSequence = {
      mode: getAssessmentSetupMode(this.props.setupMode),
      subscaleSequence: this.props.subscaleSequence || -1,
    };

    if (latestAssessment.assessmentNumber > 0) {
      AssessmentProxy.getAssessmentResultsWithExtreme(latestAssessment.patientID, latestAssessment.assessmentNumber, latestAssessment.appliedWhenID, latestAssessment.sequenceNumber, setupSequence)
        .then((results) => {
          let assessmentResults: AssessmentResultsWithExtremeDefinition[] = this.getResultsWithUpdatedQuestionText(results);
          if (this.props.setupMode !== AssessmentSetupMode.OVERALL && this.props.setupMode !== AssessmentSetupMode.CORE_MEASURES && this.props.subscaleSequence) {
            assessmentResults = results.filter((result: AssessmentResultsWithExtremeDefinition) => result.subscaleSequence === this.props.subscaleSequence);
          }
          this.setupAssessmentTable(latestAssessment, assessmentResults);
        })
        .catch((jsonDefinitionError: any) => {
          console.log(jsonDefinitionError);
        });
    } else {
      this.setState({ tableIsLoading: false });
      if (this.props.onLoaded) this.props.onLoaded();
    }
  }

  /**
   * Callback function to populate the table JS with the latest response.
   *
   * @param assessment Assessment data used to populate the chart and tables.
   * @param latestResponse user response for the comparison column.
   */
  setupAssessmentTable(assessment: PatientAssessmentDefinition, latestResponse: AssessmentResultsWithExtremeDefinition[]): void {
    if (!Utilities.isEmpty(this.state.assessmentTableData)) {
      return;
    }

    // look for extreme messages in the table and get the general message.
    let generalExtremeMsg = '';
    if (this.hasExtremeMessage(latestResponse)) {
      generalExtremeMsg = latestResponse.pop()?.extremeResponseMessage || '';
    }

    const tableQuestionProps: AssessmentResultRow[] = ApiDataUiConverter.convertAssessmentQuestionToTableProp(latestResponse);

    // Update the comparison column header to the assessment date.
    const formattedDate: string = new MHODateTime(String(assessment.dateProcessed)).getFormattedCalendarDate();
    const newHeaderText = [assessment.appliedWhenDesc, formattedDate];
    this.updateColumnHeader(newHeaderText, comparisonColumnClass);

    this.setState(
      {
        tableIsLoading: false,
        assessmentTableData: tableQuestionProps,
        foundExtremeResponse: generalExtremeMsg !== '',
        extremeResponseGeneralMessage: generalExtremeMsg,
      },
      () => {
        this.selectAssessment();
      },
    );
    if (this.props.onLoaded) this.props.onLoaded();
  }

  /**
   * Check to see if the response list has the extra entry for the general assessment's extreme message.
   *
   * @param responseList list of assessment results
   * @return true if the general extreme message is found, which indicates the extreme message.
   */
  hasExtremeMessage = (responseList: AssessmentResultsWithExtremeDefinition[]): boolean => {
    if (responseList && responseList.length) {
      return responseList[responseList.length - 1].generalMessage !== 0;
    }
    return false;
  };

  /**
   * Remove WYSIWYG HTML formatting from form JSON.
   * @param html
   * @returns
   */
  private stripHtml(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }

  /**
   * Update the column header with the assessment date. If appliedWhen is set, then update the
   * selected header column. Otherwise, update the comparison header column.
   *
   * @param assessmentLabel new column header text.
   * @param headerClassID html identifier by class to replace with the label.
   */
  updateColumnHeader(assessmentLabel: string[], headerClassID: string): void {
    // Update the comparison or select column header to the assessment date.
    const headers = document.getElementsByClassName(headerClassID);

    for (let i = 0; i < headers.length; i += 1) {
      // If patiient, just show date with no line break.
      if (this.props.isPatientView) {
        headers[i].innerHTML = assessmentLabel.join('');
      } else {
        headers[i].innerHTML = assessmentLabel.join('</br>');
      }
    }
  }

  /**
   * Compares the new assessment to the previously selected assessment, to see if they
   * have the identical identifiers.
   *
   * @param newSelectedAssessment new assessment to compare against the previously saved cache.
   * @returns true is there's a prev assessment and the new assessment is a match.
   */
  isSameAssessment(newSelectedAssessment: AssessmentCallbackData): boolean {
    if (!this.prevSelectedAssessment) {
      return false;
    }

    return (
      this.prevSelectedAssessment.patientID === newSelectedAssessment.patientID
      && this.prevSelectedAssessment.label === newSelectedAssessment.label
      && this.prevSelectedAssessment.assessmentNumber === newSelectedAssessment.assessmentNumber
      && this.prevSelectedAssessment.instrumentTypeID === newSelectedAssessment.instrumentTypeID
      && this.prevSelectedAssessment.appliedWhenID === newSelectedAssessment.appliedWhenID
    );
  }

  /**
   * Callback function to select the assessment to display.
   */
  selectAssessment(): void {
    if (!this.props.selectedAssessment || this.isSameAssessment(this.props.selectedAssessment) || Utilities.isEmpty(this.state.assessmentTableData)) {
      return;
    }
    this.setState({ tableIsLoading: true });

    this.prevSelectedAssessment = this.props.selectedAssessment;
    const assessmentLabel: string[] = this.props.selectedAssessment.label;
    AssessmentProxy.getAssessmentResultsWithExtreme(
      this.props.selectedAssessment.patientID,
      this.props.selectedAssessment.assessmentNumber,
      this.props.selectedAssessment.appliedWhenID,
      this.props.selectedAssessment.appliedWhenSequenceNumber,
    )
      .then((results) => {
        const mappedResponses: any = {};
        if (results[0].assessmentNumber === 100031) {
          results.forEach((assessmentResponse: AssessmentResultsWithExtremeDefinition) => {
            // For form 100031, append sequence number to variable name
            mappedResponses[`${assessmentResponse.variableName}-${assessmentResponse.sequenceNumber}`] = {
              responseText: assessmentResponse.responseText,
              extremeMessage: assessmentResponse.extremeResponseMessage,
            };
          });

          // Set the selected asssessment answers to the table data.
          this.setState(
            (prevState) => ({
              tableIsLoading: false,
              assessmentTableData: prevState.assessmentTableData.map((row) => {
                // decouple instance to avoid the 'no-param-reassign' jslint
                const item = { ...row };
                item.selectedAnswer = String(mappedResponses[`${row.name}-${row.sequenceNumber}`]?.responseText || '---');
                item.selectedExtremeMessage = mappedResponses[`${row.name}-${row.sequenceNumber}`]?.extremeMessage || '';
                return item; // replace original with new instance
              }),
            }),
            () => {
              // Update the comparison column header to the assessment date.
              const newHeaderText = assessmentLabel;
              this.updateColumnHeader(newHeaderText, selectedColumnClass);
            },
          );
        } else {
          results.forEach((assessmentResponse: AssessmentResultsWithExtremeDefinition) => {
            mappedResponses[assessmentResponse.variableName] = {
              responseText: assessmentResponse.responseText,
              extremeMessage: assessmentResponse.extremeResponseMessage,
            };
          });

          // Set the selected asssessment answers to the table data.
          this.setState(
            (prevState) => ({
              tableIsLoading: false,
              assessmentTableData: prevState.assessmentTableData.map((row) => {
                // decouple instance to avoid the 'no-param-reassign' jslint
                const item = { ...row };
                item.selectedAnswer = String(mappedResponses[row.name]?.responseText || '---');
                item.selectedExtremeMessage = mappedResponses[row.name]?.extremeMessage || '';
                return item; // replace original with new instance
              }),
            }),
            () => {
              // Update the comparison column header to the assessment date.
              const newHeaderText = assessmentLabel;
              this.updateColumnHeader(newHeaderText, selectedColumnClass);
            },
          );
        }

        if (this.props.onLoaded) this.props.onLoaded();
      })
      .catch((jsonDefinitionError: any) => {
        console.log(jsonDefinitionError);
        this.setState(
          (prevState) => ({
            tableIsLoading: false,
            assessmentTableData: prevState.assessmentTableData.map((row) => {
              // decouple instance to avoid the 'no-param-reassign' jslint
              const item = { ...row };
              item.selectedAnswer = '---';
              item.selectedExtremeMessage = '';
              return item; // replace original with new instance
            }),
          }),
          () => {
            // Update the comparison column header to the assessment date.
            this.updateColumnHeader(['Error: Not Found'], selectedColumnClass);
          },
        );
        if (this.props.onLoaded) this.props.onLoaded();
      });
  }

  render(): JSX.Element {
    return (
      <FlexContainer display={DisplayVariant.FLEX_COL} justify={JustifyVariant.START} align={AlignVariant.START} extraClasses="table-wrapper card-content">
        {this.state.foundExtremeResponse && (
          <span className="extreme-response-warning-text">
            <FontAwesomeIcon icon={faWarning} className="icon-control warning-icon" />
            {this.state.extremeResponseGeneralMessage}
          </span>
        )}
        <AssessmentColumnTable
          key={this.getSequenceNumber()}
          isLoading={this.state.tableIsLoading}
          isPatientView={this.props.isPatientView || false}
          suppressComparisonColumn={this.props.suppressComparisonColumn || false}
          assessmentTableData={this.state.assessmentTableData}
          isCoreMeasures={this.props.setupMode === AssessmentSetupMode.CORE_MEASURES}
        />
      </FlexContainer>
    );
  }
}
