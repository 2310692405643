import './styles.scss';

import * as React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import * as PhoneInput from 'global_elements/Inputs/TextInput/lib/phoneNumber';
import * as EmailInput from 'global_elements/Inputs/TextInput/lib/email';
import DataListsProxy, { DataListType } from 'api/dataLists/dataListsProxy';
import PageLayout from 'global_elements/Layouts/PageLayout';
import FlexContainer from 'global_elements/Layouts/FlexContainer';
import TertiaryHeader from 'global_elements/Text/TertiaryHeader';
import InlineText from 'global_elements/Text/InlineText';
import Button from 'global_elements/Button';
import LabledTextInput from 'global_elements/Inputs/TextInput/LabledTextInput';
import LabledSingleSelect from 'global_elements/Inputs/Dropdown/SingleSelect/LabledSingleSelect';
import MHODateTime from 'domain/dateTime/MHODateTime';
import { UserContext } from 'context/user';
import UserProxy from 'api/user/userProxy';
import ApiResponse from 'api/lib/models/ApiResponse';
import UserAccount from 'interfaces/users/userAccount';
import UserPatientRelationship from 'interfaces/users/userPatientRelationship';
import { UpdateAccountPatientRequest } from 'interfaces/patients/updateAccountPatientRequest';
import { CreateAccountRequest } from 'interfaces/facilityUsers/createAccountRequest';
import { PageLayoutVariant } from 'global_elements/Layouts/PageLayout/variants';
import SecondaryHeader from 'global_elements/Text/SecondaryHeader';
import { AlignVariant, DisplayVariant, JustifyVariant } from 'global_elements/Layouts/FlexContainer/variants';
import { FontColors, FontSizes } from 'global_elements/Text/variants';
import { ButtonVariant } from 'global_elements/Button/variants';
import { SelectOption } from 'types/inputProps';
import { TextInputVariant } from 'global_elements/Inputs/TextInput/variants';
import { SingleStandardDropdownStyles } from 'global_elements/Inputs/Dropdown/SingleSelect/styles';
import { ListFacility } from 'interfaces/dataLists/listFacility';
import PatientUsersProxy from 'api/patientUsers/patientUsersProxy';
import PopupWindow from 'global_elements/Layouts/PopupWindow';
import { getMsalPolicies } from 'config/msal/configMgmt';
import { useMsal } from '@azure/msal-react';
import DeleteConfirmationPopup from './DeleteConfirmationPopup';
import { AccountData, PatientData, deepEqual } from './types';

const msalPolicies = getMsalPolicies();

const PatientAccountPage = (): JSX.Element => {
  const { user } = React.useContext(UserContext);
  const [isLoading, setLoading] = React.useState<boolean>(true);
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const [relationshipOptions, setRelationshipOptions] = React.useState<SelectOption[]>([]);
  const [stateOptions, setStateOptions] = React.useState<SelectOption[]>([]);
  const [facilityOptions, setFacilityOptions] = React.useState<SelectOption[]>([]);
  const [facilities, setFacilities] = React.useState<ListFacility[]>([]);
  const [patientStays, setPatientStays] = React.useState<any[]>([]);
  const [showAddTreatmentPopup, setShowAddTreatmentPopup] = React.useState<boolean>(false);
  const [stayPin, setStayPin] = React.useState<string>('');
  const [stayPinError, setStayPinError] = React.useState<string>('');
  const [userPatientRelationship, setUserPatientRelationship] = React.useState<UserPatientRelationship>();
  const { instance } = useMsal();
  const [originalAccountData, setOriginalAccountData] = React.useState<AccountData>({
    accountPrimaryEmail: user?.name || '',
    accountFirstName: '',
    accountLastName: '',
    accountRelationship: { label: 'Select a Relationship', value: '' },
    accountAddress: '',
    accountCity: '',
    accountState: { label: 'Select a State', value: '' },
    accountZip: '',
    accountPhone: '',
    accountNotificationEmail: '',
    accountReceiveEmails: false,
    accountReceiveTexts: false,
    accountUserRoleID: 0,
    accountID: 0,
    accountStatusID: 0,
    accountPatientID: 0,
    lastLoginDate: '',
    emailSentDate: '',
  });

  const [modifiedAccountData, setModifiedAccountData] = React.useState<AccountData>({
    accountPrimaryEmail: user?.name || '',
    accountFirstName: '',
    accountLastName: '',
    accountRelationship: { label: 'Select a Relationship', value: '' },
    accountAddress: '',
    accountCity: '',
    accountState: { label: 'Select a State', value: '' },
    accountZip: '',
    accountPhone: '',
    accountNotificationEmail: '',
    accountReceiveEmails: false,
    accountReceiveTexts: false,
    accountUserRoleID: 0,
    accountID: 0,
    accountStatusID: 0,
    accountPatientID: 0,
    lastLoginDate: '',
    emailSentDate: '',
  });

  const [originalPatientData, setOriginalPatientData] = React.useState<PatientData>({
    emergencyContactFirstName: '',
    emergencyContactLastName: '',
    emergencyContactRelationship: { label: 'Select a Relationship', value: '' },
    emergencyContactPhone: '',
    emergencyContactEmail: '',
    facility: { label: 'Select a Facility', value: '' },
    providerName: '',
    providerPhone: '',
    providerWebAddress: '',
    patientID: 0,
    patientAccountID: 0,
  });

  const [modifiedPatientData, setModifiedPatientData] = React.useState<PatientData>({
    emergencyContactFirstName: '',
    emergencyContactLastName: '',
    emergencyContactRelationship: { label: 'Select a Relationship', value: '' },
    emergencyContactPhone: '',
    emergencyContactEmail: '',
    facility: { label: 'Select a Facility', value: '' },
    providerName: '',
    providerPhone: '',
    providerWebAddress: '',
    patientID: 0,
    patientAccountID: 0,
  });
  const [showDeletePopup, setShowDeletePopup] = React.useState<boolean>(false);

  const handleKeyUp = (e: any, callback: any): void => {
    if (e.key === 'Enter' && callback) {
      callback();
    }
  };

  const handleDataChange = (target: keyof AccountData | keyof PatientData, value: string | SelectOption | boolean): void => {
    if (selectedTab === 0) {
      setModifiedAccountData((prevState) => ({
        ...prevState,
        [target]: value,
      }));
    } else {
      setModifiedPatientData((prevState) => ({
        ...prevState,
        [target]: value,
      }));
    }
  };

  const handleRelationshipSelect = (option: SelectOption): void => {
    if (selectedTab === 0) {
      handleDataChange('accountRelationship', option);
    } else {
      handleDataChange('emergencyContactRelationship', option);
    }
  };

  const handleStateSelect = (option: SelectOption): void => {
    handleDataChange('accountState', option);
  };

  const handleFacilitySelect = (option: SelectOption): void => {
    const selectedFacility = facilities.find((f) => f.facilityID.toString() === option.value);
    const phone = selectedFacility && selectedFacility.phoneNumber ? selectedFacility.phoneNumber : '';
    const website = selectedFacility && selectedFacility.website ? selectedFacility.website : '';
    setModifiedPatientData((prevState) => ({
      ...prevState,
      facility: option,
      providerPhone: phone,
      providerWebAddress: website,
    }));
  };

  const getAccountPatientInfo = (patientId: number): void => {
    UserProxy.getUserPatientInfo(
      patientId,
      (accountResponse: ApiResponse<UserAccount[]>) => {
        if (accountResponse.data && accountResponse.data.length > 0) {
          const patient: UserAccount = accountResponse.data[accountResponse.data.length - 1];
          const facilityOption = facilityOptions.find((f) => f.value === patient.facilityID?.toString());
          const patientInfo = {
            emergencyContactFirstName: patient.contactFirstName,
            emergencyContactLastName: patient.contactLastName,
            emergencyContactRelationship: { label: patient.contactRelationship, value: patient.contactRelationshipID },
            emergencyContactPhone: patient.contactPhone,
            emergencyContactEmail: patient.contactEmail,
            facility: { label: patient.facilityName, value: patient.facilityID },
            providerName: patient.facilityID?.toString(),
            providerPhone: patient.facilityPhone,
            providerWebAddress: patient.facilityWebsite,
            patientAccountID: patient.patientAccountID,
            patientID: patient.patientID,
          };
          if (facilityOption) patientInfo.facility = { label: facilityOption.label, value: parseInt(facilityOption.value, 10) };
          setOriginalPatientData(patientInfo);
          setModifiedPatientData(patientInfo);
        }
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );
  };

  const getUserPatientRelationshipInfo = (accountId: number, patientId: number): void => {
    UserProxy.getUserPatientRelationshipInfo(
      accountId,
      patientId,
      (resp: ApiResponse<UserPatientRelationship[]>) => {
        if (resp.data && resp.data.length) {
          const option = resp.data[0];
          const selectOption = { label: option.relationship, value: option.relationshipID.toString() };
          setUserPatientRelationship(option);
          setOriginalAccountData((prevState) => ({
            ...prevState,
            accountRelationship: selectOption,
          }));
          setModifiedAccountData((prevState) => ({
            ...prevState,
            accountRelationship: selectOption,
          }));
          handleRelationshipSelect(selectOption);
        }
      },
      (err) => {
        console.log(err);
      },
    );
  };

  const handleAccountInfoResponse = (resp: any): void => {
    const acct: UserAccount = resp[0];
    const selectStateOption = { label: acct.state, value: acct.state };
    const userAccount: any = {
      accountID: acct.accountID,
      accountPrimaryEmail: acct.accountEmail,
      accountFirstName: acct.firstName,
      accountLastName: acct.lastName,
      accountAddress: acct.address,
      accountCity: acct.city,
      accountState: selectStateOption,
      accountZip: acct.zipCode,
      accountPhone: acct.phone,
      accountNotificationEmail: acct.notificationEmail,
      accountReceiveEmails: acct.emailNotify,
      accountReceiveTexts: acct.textNotify,
      accountUserRoleID: acct.userRoleID,
      accountStatusID: acct.statusID,
      accountPatientID: acct.patientID,
      emailSentDate: acct.emailSentDate,
      lastLoginDate: acct.lastLoginDate,
    };
    setOriginalAccountData(userAccount);
    setModifiedAccountData(userAccount);
    getUserPatientRelationshipInfo(acct.accountID, acct.patientID);
    getAccountPatientInfo(acct.patientID);
  };

  React.useEffect(() => {
    DataListsProxy.getOptionList(
      DataListType.Relationship,
      (response) => {
        const newRelationshipOptions: SelectOption[] = [];

        if (response.data) {
          response.data.forEach((option) => {
            newRelationshipOptions.push({
              label: option.lookupDesc,
              value: option.lookupID.toString(),
            });
          });
        }

        setRelationshipOptions(newRelationshipOptions);
        setLoading(false);
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );
  }, []);

  React.useEffect(() => {
    DataListsProxy.getStateList(
      (response) => {
        const newStateOptions: SelectOption[] = [];
        if (response.data) {
          response.data.forEach((option) => {
            newStateOptions.push({
              label: option.stateDesc.toString(),
              value: option.stateDesc.toString(),
            });
          });
        }
        setStateOptions(newStateOptions);
        setLoading(false);
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );
  }, []);

  React.useEffect(() => {
    const userAccountIdStr = user ? user.accountId : '-1';
    const userAccountId = parseInt(userAccountIdStr, 10);

    DataListsProxy.getAllUserFacilities(
      (resp) => {
        if (resp.data && resp.data.length) {
          setFacilities(resp.data);
          setFacilityOptions(resp.data.map((f) => ({
            label: f.facilityName,
            value: f.facilityID.toString(),
          })));
        }
      },
      (resp) => console.log(resp),
    );

    UserProxy.getUserAccountInfo(
      userAccountId,
      (accountResponse: ApiResponse<UserAccount[]>) => {
        if (accountResponse.data && accountResponse.data.length > 0) {
          handleAccountInfoResponse(accountResponse.data);
        }
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );
  }, []);

  React.useEffect(() => {
    const userAccountIdStr = user ? user.accountId : '-1';
    const userAccountId = parseInt(userAccountIdStr, 10);

    UserProxy.getUserAccountInfo(
      userAccountId,
      (accountResponse: ApiResponse<UserAccount[]>) => {
        if (accountResponse.data && accountResponse.data.length > 0) {
          handleAccountInfoResponse(accountResponse.data);
        }
      },
      (errorResponse) => {
        console.log(errorResponse);
      },
    );
  }, []);

  const getAndSetPatientStays = (): void => {
    PatientUsersProxy
      .getPatientStays(originalAccountData.accountPatientID)
      .then((resp) => {
        setPatientStays(resp);
      }).catch((errorResponse) => {
        console.log(errorResponse);
      });
  };

  React.useEffect(() => {
    getAndSetPatientStays();
  }, [originalAccountData]);

  const handleInputEvent = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.type === 'text' || e.target.type === 'email') {
      if (
        e.target.name !== 'accountPhone'
          && e.target.name !== 'emergencyContactPhone'
      ) {
        handleDataChange(e.target.name as (keyof AccountData | keyof PatientData), e.target.value);
      } else if (PhoneInput.validPhoneInput(e)) {
        const maskedInput = PhoneInput.maskInputAsPhoneNumber(e);

        if (maskedInput !== undefined) {
          if (selectedTab === 0) {
            handleDataChange('accountPhone', maskedInput);
          } else {
            handleDataChange('emergencyContactPhone', maskedInput);
          }
        }
      }
    } else {
      handleDataChange(e.target.name as (keyof AccountData | keyof PatientData), e.target.checked);
    }
  };

  const phoneError = (phone: string): string | undefined => {
    if (!PhoneInput.validPhoneNumber(phone)) {
      return 'Invalid Phone Number';
    }
    return undefined;
  };

  const isDataModified = (): boolean => {
    if (selectedTab === 0) {
      if (!deepEqual(originalAccountData, modifiedAccountData)) {
        return true;
      }
    } else if (!deepEqual(originalPatientData, modifiedPatientData)) {
      return true;
    }

    return false;
  };

  const invalidAccountData = (): boolean => (
    modifiedAccountData.accountFirstName === ''
      || modifiedAccountData.accountLastName === ''
      || modifiedAccountData.accountRelationship?.value === ''
      || !!EmailInput.inputError(modifiedAccountData.accountNotificationEmail)
      || !!phoneError(modifiedAccountData.accountPhone)
  );

  const invalidPatientData = (): boolean => (
    !!EmailInput.inputError(modifiedPatientData.emergencyContactEmail)
      || !!phoneError(modifiedPatientData.emergencyContactPhone)
  );

  const disableSave = (): boolean => {
    if (selectedTab === 0) {
      return (!isDataModified() || invalidAccountData());
    }

    return (!isDataModified() || invalidPatientData());
  };

  const cancelChanges = (): void => {
    if (selectedTab === 0) {
      setModifiedAccountData(originalAccountData);
    } else {
      setModifiedPatientData(originalPatientData);
    }
  };

  const getUpdatedAccountInfo = (): CreateAccountRequest => {
    const acct = modifiedAccountData;
    return {
      AccountEmail: acct.accountPrimaryEmail,
      AccountID: acct.accountID ?? null,
      UserRoleID: acct.accountUserRoleID,
      FirstName: acct.accountFirstName,
      LastName: acct.accountLastName,
      StatusID: acct.accountStatusID,
      UpdatedBy: acct.accountPrimaryEmail,
      Phone: acct.accountPhone,
      Address: acct.accountAddress,
      RelationshipID: parseInt(acct.accountRelationship.value, 10),
      City: acct.accountCity,
      State: acct.accountState.value,
      ZipCode: acct.accountZip,
      EmailNotify: acct.accountReceiveEmails,
      TextNotify: acct.accountReceiveTexts,
      EmailSentDate: acct.emailSentDate,
      LastLoginDate: acct.lastLoginDate || new MHODateTime(new Date()).getDateTimeForPost(),
    };
  };

  const getUpdatedAccountPatientInfo = (): UpdateAccountPatientRequest => {
    const acct = modifiedPatientData;
    const relationshipId = acct.emergencyContactRelationship.value === '0' ? null : parseInt(acct.emergencyContactRelationship.value, 10);
    const facilityValue = acct.facility.value ? Number(acct.facility.value) : -1;
    return {
      patientAccountID: acct.patientAccountID,
      patientID: originalAccountData.accountPatientID,
      contactFirstName: acct.emergencyContactFirstName,
      contactLastName: acct.emergencyContactLastName,
      contactRelationshipID: relationshipId,
      contactPhone: acct.emergencyContactPhone,
      contactEmail: acct.emergencyContactEmail,
      facilityID: facilityValue,
      statusID: 1,
    };
  };

  const getPatientStaysMarkup = (): any => {
    if (patientStays && patientStays.length) {
      return patientStays.map((stay: any) => (
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          align={AlignVariant.CENTER}
          justify={JustifyVariant.START}
          extraClasses="patient-stays--container"
        >
          <InlineText text={`${stay.dateAdmitted ? new MHODateTime(stay.dateAdmitted).getFormattedCalendarDate() : 'N/A'} - ${stay.dateDischarged ? new MHODateTime(stay.dateDischarged).getFormattedCalendarDate() : 'N/A'}`} fontColor={FontColors.LIGHT_GRAY} fontSize={FontSizes.REGULAR} />
          <InlineText text={stay.facilityName} fontColor={FontColors.LIGHT_GRAY} fontSize={FontSizes.REGULAR} />
        </FlexContainer>
      ));
    }
    return '';
  };

  const saveUserPatientRelationship = (): void => {
    const acct = modifiedAccountData;
    UserProxy.saveUserPatientRelationshipInfo(
      [{
        accountID: userPatientRelationship?.accountID,
        accountPatientID: userPatientRelationship?.accountPatientID,
        careID: userPatientRelationship?.careID,
        patientID: userPatientRelationship?.patientID,
        relationshipID: parseInt(acct.accountRelationship.value, 10),
        statusID: 1,
        UpdatedBy: user?.email,
      }],
      (resp) => console.log(resp),
      (e) => console.log(e),
    );
  };

  const saveChanges = (): void => {
    if (selectedTab === 0) {
      UserProxy.postUserAccountInfo(
        [getUpdatedAccountInfo()],
        () => {
          setOriginalAccountData(modifiedAccountData);
          saveUserPatientRelationship();
        },
        (resp) => console.log('Error', resp),
      );
    } else {
      console.log(getUpdatedAccountPatientInfo());
      UserProxy.postUserPatientInfo(
        [getUpdatedAccountPatientInfo()],
        () => setOriginalPatientData(modifiedPatientData),
        (resp) => console.log('Error', resp),
      );
    }
  };

  const linkPatientStay = (): void => {
    UserProxy.linkPatientStay(
      originalAccountData.accountID,
      stayPin,
      (resp) => {
        console.log(resp);
        if (resp && resp.data.toString() === 'No LinkStay Found') {
          setStayPinError('The registration PIN does not match our records for the current patient.');
        } else {
          setShowAddTreatmentPopup(false);
          setOriginalPatientData(modifiedPatientData);
          getAndSetPatientStays();
        }
      },
      (e) => {
        console.log(e);
        setStayPinError('Error linking stay.  Please try again.');
      },
    );
  };

  const validPinString = (pin: string): boolean => (
    new RegExp(/^\d{8}$/).test(pin)
  );

  const pinError = (): string | undefined => {
    let errorMsg;
    if (stayPin !== '' && !validPinString(stayPin)) {
      errorMsg = 'Invalid';
    }

    return errorMsg;
  };

  const onTabSelect = (index: number, lastIndex: number): boolean => {
    if (index === lastIndex) {
      return false;
    }

    switch (index) {
      case 0: // Account Information
        setSelectedTab(0);
        break;
      case 1: // Patient Information
        setSelectedTab(1);
        break;
      default:
        break;
    }

    return true;
  };

  const getLinkStayPopup = (): JSX.Element => (
    <PopupWindow>
      <SecondaryHeader text="Add Inpatient/Outpatient Treatment" fontColor={FontColors.PRIMARY} marginBottomPx={16} marginTopPx={32} />
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.CENTER}
        justify={JustifyVariant.CENTER}
        extraClasses="first-time-account-setup__account-info link-stays--popup"
      >
        <LabledTextInput
          variant={TextInputVariant.PRIMARY}
          label="Registration PIN (provided by your facility)*"
          type="text"
          name="stayInfoPin"
          value={stayPin}
          onChange={(e) => setStayPin(e.target.value.slice(0, 8))}
          error={pinError()}
        />
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          align={AlignVariant.START}
          justify={JustifyVariant.SPACE_EVENLY}
          extraClasses="first-time-account-setup__account-info"
        >
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={() => { setShowAddTreatmentPopup(false); setStayPin(''); setStayPinError(''); }}
          >
            <InlineText text="Cancel" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
          </Button>
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={() => { linkPatientStay(); }}
            disabled={stayPin === '' || !!pinError()}
          >
            <InlineText text="Submit" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
          </Button>
        </FlexContainer>
        {stayPinError && <InlineText text={stayPinError} fontColor={FontColors.HIGH_PRIORITY} fontSize={FontSizes.REGULAR} />}
      </FlexContainer>
    </PopupWindow>
  );

  const accountPanel = (): JSX.Element => (
    <>
      {showDeletePopup && (
        <DeleteConfirmationPopup
          accountID={originalAccountData.accountID}
          patientID={originalAccountData.accountPatientID}
          onCancel={() => setShowDeletePopup(false)}
        />
      )}
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
        extraClasses="patient-account-settings-page__account__email"
      >
        <TertiaryHeader text="Email Address/Account ID" fontColor={FontColors.PRIMARY} marginBottomPx={9} />
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          align={AlignVariant.CENTER}
          justify={JustifyVariant.SPACE_BETWEEN}
          extraClasses="patient-account-settings-page__account__email__content"
        >
          <InlineText text={modifiedAccountData.accountPrimaryEmail} fontColor={FontColors.LIGHT_GRAY} fontSize={FontSizes.REGULAR} />
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={() => { instance.loginRedirect({ authority: msalPolicies.authorities.forgotPassword.authority, scopes: [] }); }}
          >
            <InlineText text="Change Password" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
          </Button>
        </FlexContainer>
      </FlexContainer>
      <form>
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="patient-account-settings-page__account__information"
        >
          <TertiaryHeader text="Account Holder Information" fontColor={FontColors.PRIMARY} marginBottomPx={9} />
          <FlexContainer
            display={DisplayVariant.FLEX_ROW}
            align={AlignVariant.START}
            justify={JustifyVariant.SPACE_BETWEEN}
            extraClasses="patient-account-settings-page__account__information__first-row"
          >
            <LabledTextInput
              variant={TextInputVariant.PRIMARY}
              label="First Name *"
              type="text"
              name="accountFirstName"
              value={modifiedAccountData.accountFirstName}
              onChange={handleInputEvent}
              autoFocus
            />
            <LabledTextInput
              variant={TextInputVariant.PRIMARY}
              label="Last Name *"
              type="text"
              name="accountLastName"
              value={modifiedAccountData.accountLastName}
              onChange={handleInputEvent}
            />
            {(!modifiedAccountData || !modifiedAccountData.accountRelationship)
                && (
                  <LabledSingleSelect
                    label="Relationship to Patient *"
                    styles={SingleStandardDropdownStyles}
                    options={[]}
                    defaultValue={{ label: 'Loading...', value: '' }}
                    value={{ label: 'Loading...', value: '' }}
                    onSelection={handleRelationshipSelect}
                  />
                )}
            {!!modifiedAccountData && !!modifiedAccountData.accountRelationship
                && (
                  <LabledSingleSelect
                    label="Relationship to Patient *"
                    styles={SingleStandardDropdownStyles}
                    options={relationshipOptions}
                    defaultValue={modifiedAccountData.accountRelationship}
                    value={modifiedAccountData.accountRelationship}
                    onSelection={handleRelationshipSelect}
                  />
                )}
          </FlexContainer>
          <FlexContainer
            display={DisplayVariant.FLEX_ROW}
            align={AlignVariant.START}
            justify={JustifyVariant.SPACE_BETWEEN}
            extraClasses="patient-account-settings-page__account__information__second-row"
          >
            <LabledTextInput
              variant={TextInputVariant.PRIMARY}
              label="Address"
              type="text"
              name="accountAddress"
              value={modifiedAccountData.accountAddress}
              onChange={handleInputEvent}
            />
            <LabledTextInput
              variant={TextInputVariant.PRIMARY}
              label="City"
              type="text"
              name="accountCity"
              value={modifiedAccountData.accountCity}
              onChange={handleInputEvent}
            />
            {/* ***added state dropdown */}
            {stateOptions.length === 0
                && (
                  <LabledSingleSelect
                    label="State"
                    styles={SingleStandardDropdownStyles}
                    options={[]}
                    defaultValue={{ label: 'Loading...', value: '' }}
                    value={{ label: 'Loading...', value: '' }}
                    onSelection={handleStateSelect}
                  />
                )}
            {stateOptions.length > 0
                && (
                  <LabledSingleSelect
                    label="State"
                    styles={SingleStandardDropdownStyles}
                    options={stateOptions}
                    defaultValue={modifiedAccountData.accountState ?? { label: 'Select a State', value: '' }}
                    value={modifiedAccountData.accountState}
                    onSelection={handleStateSelect}
                  />
                )}
            <LabledTextInput
              variant={TextInputVariant.PRIMARY}
              label="ZIP Code"
              type="text"
              name="accountZip"
              value={modifiedAccountData.accountZip}
              onChange={handleInputEvent}
            />
          </FlexContainer>
          <FlexContainer
            display={DisplayVariant.FLEX_ROW}
            align={AlignVariant.START}
            justify={JustifyVariant.SPACE_BETWEEN}
            extraClasses="patient-account-settings-page__account__information__third-row"
          >
            <LabledTextInput
              variant={TextInputVariant.PRIMARY}
              label="Phone"
              type="text"
              name="accountPhone"
              value={modifiedAccountData.accountPhone}
              onChange={handleInputEvent}
              error={phoneError(modifiedAccountData.accountPhone)}
            />
            <LabledTextInput
              variant={TextInputVariant.PRIMARY}
              label="Email for Notifications"
              type="email"
              name="accountNotificationEmail"
              value={modifiedAccountData.accountNotificationEmail}
              onChange={handleInputEvent}
              error={EmailInput.inputError(modifiedAccountData.accountNotificationEmail)}
            />
            <FlexContainer
              display={DisplayVariant.FLEX_COL}
              align={AlignVariant.START}
              justify={JustifyVariant.START}
              extraClasses="patient-account-settings-page__account__information__third-row__notifications"
            >
              <TertiaryHeader text="Notifications" fontColor={FontColors.SECONDARY} marginBottomPx={12} />
              <FlexContainer
                display={DisplayVariant.FLEX_ROW}
                align={AlignVariant.CENTER}
                justify={JustifyVariant.START}
                extraClasses="patient-account-settings-page__account__information__third-row__notifications__toggles"
              >
                <span>
                  <InlineText text="Email" fontColor={FontColors.LIGHT_GRAY} fontSize={FontSizes.LARGE} bold />
                  <label className="toggle">
                    <input
                      onKeyUp={
                        (e) => handleKeyUp(e, () => setModifiedAccountData({ ...modifiedAccountData, accountReceiveEmails: !modifiedAccountData.accountReceiveEmails }))
                      }
                      name="accountReceiveEmails"
                      type="checkbox"
                      checked={modifiedAccountData.accountReceiveEmails}
                      onChange={handleInputEvent}
                    />
                    <span className="slider" />
                  </label>
                </span>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer
            display={DisplayVariant.FLEX_ROW}
            align={AlignVariant.START}
            justify={JustifyVariant.START}
            extraClasses="patient-account-settings-page__account__information__buttons"
          >
            <FlexContainer
              display={DisplayVariant.FLEX_ROW}
              align={AlignVariant.START}
              justify={JustifyVariant.START}
              extraClasses="patient-account-settings-page__account__information__buttons__delete"
            >
              <Button
                variant={ButtonVariant.INVISIBLE}
                onClick={() => setShowDeletePopup(true)}
              >
                <InlineText text="Delete Account" fontColor={FontColors.PRIMARY} fontSize={FontSizes.REGULAR} bold underlined />
              </Button>
            </FlexContainer>
            <FlexContainer
              display={DisplayVariant.FLEX_ROW}
              align={AlignVariant.START}
              justify={JustifyVariant.END}
              extraClasses="patient-account-settings-page__account__information__buttons__save"
            >
              <Button
                variant={ButtonVariant.SECONDARY}
                onClick={cancelChanges}
                disabled={!isDataModified()}
              >
                <InlineText text="Cancel" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
              </Button>
              <Button
                variant={ButtonVariant.PRIMARY}
                onClick={saveChanges}
                disabled={disableSave()}
              >
                <InlineText text="Save Changes" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
              </Button>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </form>
    </>
  );

  const patientPanel = ():JSX.Element => (
    <>
      <form>
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="patient-account-settings-page__patient__emergency-contact"
        >
          <TertiaryHeader text="Emergency Contact" fontColor={FontColors.PRIMARY} marginBottomPx={9} />
          <FlexContainer
            display={DisplayVariant.FLEX_ROW}
            align={AlignVariant.START}
            justify={JustifyVariant.SPACE_BETWEEN}
            extraClasses="patient-account-settings-page__patient__emergency-contact__first-row"
          >
            <LabledTextInput
              variant={TextInputVariant.PRIMARY}
              label="First Name"
              type="text"
              name="emergencyContactFirstName"
              value={modifiedPatientData.emergencyContactFirstName}
              onChange={handleInputEvent}
              autoFocus
            />
            <LabledTextInput
              variant={TextInputVariant.PRIMARY}
              label="Last Name"
              type="text"
              name="emergencyContactLastName"
              value={modifiedPatientData.emergencyContactLastName}
              onChange={handleInputEvent}
            />
            <LabledSingleSelect
              styles={SingleStandardDropdownStyles}
              label="Relationship to Patient"
              options={relationshipOptions}
              defaultValue={modifiedPatientData.emergencyContactRelationship}
              value={modifiedPatientData.emergencyContactRelationship}
              onSelection={handleRelationshipSelect}
            />
          </FlexContainer>
          <FlexContainer
            display={DisplayVariant.FLEX_ROW}
            align={AlignVariant.START}
            justify={JustifyVariant.SPACE_BETWEEN}
            extraClasses="patient-account-settings-page__patient__emergency-contact__second-row"
          >
            <LabledTextInput
              variant={TextInputVariant.PRIMARY}
              label="Phone Number"
              type="text"
              name="emergencyContactPhone"
              value={modifiedPatientData.emergencyContactPhone}
              onChange={handleInputEvent}
              error={phoneError(modifiedPatientData.emergencyContactPhone)}
            />
            <LabledTextInput
              variant={TextInputVariant.PRIMARY}
              label="Email Address"
              type="email"
              name="emergencyContactEmail"
              value={modifiedPatientData.emergencyContactEmail}
              onChange={handleInputEvent}
              error={EmailInput.inputError(modifiedPatientData.emergencyContactEmail)}
            />
          </FlexContainer>
        </FlexContainer>
        <FlexContainer
          display={DisplayVariant.FLEX_COL}
          align={AlignVariant.START}
          justify={JustifyVariant.START}
          extraClasses="patient-account-settings-page__patient__emergency-contact"
        >
          <TertiaryHeader text="Preferred Facility" fontColor={FontColors.PRIMARY} marginBottomPx={9} />
          <FlexContainer
            display={DisplayVariant.FLEX_ROW}
            align={AlignVariant.START}
            justify={JustifyVariant.SPACE_BETWEEN}
            extraClasses="patient-account-settings-page__patient__emergency-contact__first-row"
          >
            <LabledSingleSelect
              styles={SingleStandardDropdownStyles}
              label="Facility"
              options={facilityOptions}
              defaultValue={modifiedPatientData.facility as SelectOption}
              value={modifiedPatientData.facility as SelectOption}
              onSelection={handleFacilitySelect}
            />
            <LabledTextInput
              variant={TextInputVariant.PRIMARY}
              label="Phone Number"
              type="text"
              disabled={1}
              name="providerPhone"
              value={modifiedPatientData.providerPhone}
              onChange={handleInputEvent}
            />
            <LabledTextInput
              variant={TextInputVariant.PRIMARY}
              label="Website Address"
              type="text"
              disabled={1}
              name="providerWebAddress"
              value={modifiedPatientData.providerWebAddress}
              onChange={handleInputEvent}
            />
          </FlexContainer>
        </FlexContainer>
        <FlexContainer
          display={DisplayVariant.FLEX_ROW}
          align={AlignVariant.START}
          justify={JustifyVariant.END}
          extraClasses="patient-account-settings-page__patient__buttons"
        >
          <Button
            variant={ButtonVariant.SECONDARY}
            onClick={cancelChanges}
            disabled={!isDataModified()}
          >
            <InlineText text="Cancel" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
          </Button>
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={saveChanges}
            disabled={disableSave()}
          >
            <InlineText text="Save Changes" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
          </Button>
        </FlexContainer>
      </form>
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
        extraClasses="patient-account-settings-page__patient__treatments"
      >
        <TertiaryHeader text="Previous Inpatient/Outpatient Treatments" fontColor={FontColors.PRIMARY} marginBottomPx={9} />
        <div className="patient-stays">
          {getPatientStaysMarkup()}
        </div>
      </FlexContainer>
      <FlexContainer
        display={DisplayVariant.FLEX_ROW}
        align={AlignVariant.START}
        justify={JustifyVariant.END}
        extraClasses="patient-account-settings-page__patient__add-treatment"
      >
        <Button
          variant={ButtonVariant.SECONDARY}
          onClick={() => { setShowAddTreatmentPopup(true); }}
        >
          <InlineText text="Add an Inpatient/Outpatient Treatment" fontColor={FontColors.BACKGROUND} fontSize={FontSizes.REGULAR} bold />
        </Button>
      </FlexContainer>
    </>
  );

  return (
    <PageLayout
      layout={PageLayoutVariant.PADDED}
      testText="Patient Account Page"
      loadingTitle={isLoading ? 'Account Information and Settings' : undefined}
      loadingText={isLoading ? 'Loading Account Settings...' : undefined}
    >
      <FlexContainer
        display={DisplayVariant.FLEX_COL}
        align={AlignVariant.START}
        justify={JustifyVariant.START}
        extraClasses="patient-account-settings-page"
      >
        <Tabs onSelect={onTabSelect}>
          <TabList>
            <Tab disabled={isDataModified()}>
              {selectedTab === 0 ? (
                <InlineText
                  text="Account Information"
                  fontColor={FontColors.DARK}
                  fontSize={FontSizes.LARGE}
                />
              ) : (
                <InlineText
                  text="Account Information"
                  fontColor={FontColors.BACKGROUND}
                  fontSize={FontSizes.LARGE}
                />
              )}
            </Tab>
            <Tab disabled={isDataModified()}>
              {selectedTab === 1 ? (
                <InlineText
                  text="Patient Information"
                  fontColor={FontColors.DARK}
                  fontSize={FontSizes.LARGE}
                />
              ) : (
                <InlineText
                  text="Patient Information"
                  fontColor={FontColors.BACKGROUND}
                  fontSize={FontSizes.LARGE}
                />
              )}
            </Tab>
          </TabList>

          <TabPanel>
            {accountPanel()}
          </TabPanel>
          <TabPanel>
            {patientPanel()}
          </TabPanel>
        </Tabs>
        {showAddTreatmentPopup && getLinkStayPopup()}
      </FlexContainer>

    </PageLayout>
  );
};

export default PatientAccountPage;
