import ApiProxy from 'api/lib/ApiService';
import ApiProxyV2 from 'api/lib/ApiService/v2';
import ApiResponse, { ApiResponseV2 } from 'api/lib/models/ApiResponse';
import { URLS } from 'constants/appUrls';
import { PatientSearchCriteria, PatientSearchResult } from 'interfaces/patients/patientSearchResult';
import { PatientID, PatientData, PatientAccountData, RegistrationPIN, FacilityAccountData, PatientConsentData, PatientConsentPostData } from 'interfaces/patients/patientData';
import { AssessmentData } from 'interfaces/assessments/assessmentData';
import { NotificationData } from 'interfaces/patients/notificationData';

class PatientUsersProxy {
  static Search(
    searchArgs: PatientSearchCriteria,
    success: (e: ApiResponse<PatientSearchResult[]>) => void,
    failure: (e: ApiResponse<PatientSearchResult[]>) => void,
  ): void {
    const searchArray: string[] = [];
    const keys: (keyof PatientSearchCriteria)[] = ['fname', 'lname', 'fid', 'email', 'acctnum', 'medrecnum'];

    keys.forEach((key) => {
      const arg = searchArgs[key as keyof PatientSearchCriteria];
      if (arg && arg !== '') {
        searchArray.push(`${key}=${arg}`);
      }
    });

    let searchString = '';

    for (let i = 0; i < searchArray.length; i += 1) {
      searchString += (searchString === '' ? `?${searchArray[i]}` : `&${searchArray[i]}`);
    }

    const url = `${URLS.MHO_API}/ListPat${searchString}`;

    ApiProxy.get<PatientSearchResult>(url, (response: any) => {
      success(response);
    }, (response: any) => {
      failure(response);
    });
  }

  static getPatient(
    patientID: PatientID,
    success: (e: ApiResponse<PatientData[]>) => void,
    failure: (e: ApiResponse<PatientData[]>) => void,
  ): void {
    const url = `${URLS.MHO_API}/Patient/${patientID}`;

    ApiProxy.get<PatientData[]>(url, (response: any) => {
      success(response);
    }, (response: any) => {
      failure(response);
    });
  }

  static postPatient(
    data: PatientData[],
  ): Promise<ApiResponseV2<number>> {
    const url = `${URLS.MHO_API}/Patient`;

    return ApiProxyV2.postWithResponse<PatientData[], number>(
      url,
      data,
    );
  }

  static getPatientNotifications(
    patientID: PatientID,
    success: (e: ApiResponse<NotificationData[]>) => void,
    failure: (e: ApiResponse<any>) => void,
  ): void {
    const url = `${URLS.MHO_API}/PatientNtf/${patientID}`;

    ApiProxy.get<NotificationData[]>(url, (response: any) => {
      success(response);
    }, (response: any) => {
      failure(response);
    });
  }

  static savePatientNotification(
    notification: NotificationData,
    success: (e: ApiResponse<any>) => void,
    failure: (e: ApiResponse<any>) => void,
  ): void {
    const url = `${URLS.MHO_API}/PatientNtf`;

    ApiProxy.post<number>(
      url,
      JSON.stringify([notification]),
      (response: any) => {
        success(response);
      },
      (response: any) => {
        failure(response);
      },
    );
  }

  /**
    * Retrieve all assessments available to the patient, given the patient ID.
    *
    * @param patientID ID of the patient.
    * @return {Promise} a list of current assessments.
    */
  static getPatientAssessments(patientID: PatientID): Promise<AssessmentData[]> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/Dashboard/Patient/Current/${patientID}`;

      ApiProxy.get<AssessmentData[]>(url, (response: ApiResponse<AssessmentData[]>) => {
        resolve(response?.data || []);
      }, (response: any) => {
        reject(response);
      });
    });
  }

  /**
    * Retrieve all completed assessments taken by the patient, given the patient ID.
    *
    * @param patientID ID of the patient.
    * @return {Promise} a list of completed assessments.
    */
  static getPatientCompletedAssessments(patientID: PatientID): Promise<AssessmentData[]> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/Dashboard/Patient/History/${patientID}`;

      ApiProxy.get<AssessmentData[]>(url, (response: ApiResponse<AssessmentData[]>) => {
        resolve(response?.data || []);
      }, (response: any) => {
        reject(response);
      });
    });
  }

  /**
    * Retrieve patient stays
    *
    * @param patientID ID of the patient.
    * @return {Promise} a list of patient stays.
    */
  static getPatientStays(patientID: PatientID): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${URLS.MHO_API}/Stays/${patientID}`;

      ApiProxy.get<any>(url, (response: ApiResponse<any>) => {
        resolve(response?.data || []);
      }, (response: any) => {
        reject(response);
      });
    });
  }

  static async postPatientAccount(
    data: PatientAccountData[] | FacilityAccountData[],
  ): Promise<ApiResponseV2<number>> {
    const url = `${URLS.MHO_API}/AccountAll`

    return ApiProxyV2.postWithResponse<PatientAccountData[] | FacilityAccountData[], number>(
      url,
      data,
    )
  }

  static postRegistrationPIN(
    data: RegistrationPIN[],
    success: (e: ApiResponse<number>) => void,
    failure: (e: ApiResponse<number>) => void,
  ): void {
    const url = `${URLS.MHO_API}/RegPin`;

    ApiProxy.post<number>(
      url,
      JSON.stringify(data),
      (response: any) => {
        success(response);
      },
      (response: any) => {
        failure(response);
      },
    );
  }

  static async getPatientConsent(patientID: PatientID): Promise<PatientConsentData[]> {
    const url = `${URLS.MHO_API}/PConsent/${patientID}`

    const response = await ApiProxy.alternativeGet<PatientConsentData[]>(url, {})
    if (response.data?.length) {
      return response.data
    }

    return []
  }

  static async acceptPatientConsent(patientConsentPostData: PatientConsentPostData[]): Promise<ApiResponseV2<number>> {
    const url = `${URLS.MHO_API}/PConsent/`
    return ApiProxyV2.postWithResponse<PatientConsentPostData[], number>(url, patientConsentPostData)
  }

  static async SaveDeletePatientAccountLink(accountID: number, patientID: number): Promise<ApiResponseV2<undefined>> {
    const data = [{
      AccountID: accountID,
      PatientID: patientID,
    }];
    return ApiProxyV2.post(`${URLS.MHO_API}/DelPAcct`, data);
  }

  static async VerifyDeletePatientAccountLink(hash: string): Promise<ApiResponseV2<string>> {
    const url = `${URLS.MHO_API}/VerifyDPAHash/${hash}`;
    return ApiProxyV2.get<string>(url, undefined, false, true);
  }

  static async SaveDeletePatientAccountConfirmation(hash: string): Promise<ApiResponseV2<undefined>> {
    const url = `${URLS.MHO_API}/DelPAConf/${hash}`;
    return ApiProxyV2.post(url, undefined);
  }
}

export default PatientUsersProxy;
